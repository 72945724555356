/*
   For bundle size reasons (and ranking), you must import and register all icons that site needs here
*/

import { dom, library } from "@fortawesome/fontawesome-svg-core"
import { faCopy } from "@fortawesome/free-regular-svg-icons"
import {
	faArrowCircleRight,
	faArrowUpRightFromSquare,
	faCircleInfo,
	faCommentDots,
} from "@fortawesome/free-solid-svg-icons"

library.add(faArrowCircleRight, faCommentDots, faCircleInfo, faArrowUpRightFromSquare)
library.add(faCopy)

dom.watch()
